<template>
  <div>
    <div class="app flex-row align-items-center bg-gray-50">
      <Toast />
      <div class=" flex-row align-items-center" v-if='this.isErrorPage'>
        <b-jumbotron class='bg-transparent'>
          <template #header>Oops, There is Some Error</template>
          <div class='p-3'>
            <h5 class='card-title mb-0 font-weight-bold' v-if='this.message'>{{ this.message }}</h5>
            <h5 class='card-title mb-0 font-weight-bold' v-else>Error Not Found, Please Kindly Try Again</h5>
          </div>

          <router-link class="btn btn-sm btn-dark  " size="md" :to="{ path: '/' }">
            <em class="fa fa-backward btn-sm btn-dark "></em> Return To Home Page
          </router-link>
        </b-jumbotron>
      </div>
      <div v-else class="container flex relative justify-center h-screen items-center bg-gray-50 text-left">
        <div class="w-2/5">
          <div class="flex justify-center">
            <img src="../../assets/img/gramedia_digital.png" alt="Gramedia Digital" class="mb-4" />
          </div>
          <div class="rounded-lg h-auto p-4 border shadow-md flex justify-center bg-white">
            <div class="flex-col w-full justify-center">
              <h4 class="font-bold mt-4 mb-3">Change Password</h4>
              <p class="text-base">Enter your new password and retype it to confirm.</p>
              <div class="mt-0 w-full">
                <label for="password" class="block w-full mb-2 text-sm font-medium text-gray-700">New Password</label>
                <div class="relative w-full">
                  <input :type="showPassword ? 'text' : 'password'" placeholder="e.g.: Password01^"
                    class="shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg" v-model="Form.password"
                    @input="validatePassword" required />
                    <svg v-if="!showPassword" @click="toggleShowPassword"
                    class="absolute right-2 top-1/2 text-gray-500 transform -translate-y-1/2 h-5 w-5 cursor-pointer"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                    viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-width="2"
                      d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                    <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    :class="showPassword ? 'text-gray-700' : 'text-gray-400'"
                  </svg>
                  <svg v-else @click="toggleShowPassword"  class="absolute text-gray-500 right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 cursor-pointer"
                  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg>
                </div>
              </div>

              <div class="mt-3 mb-2 w-full">
                <label for="passwordVerify" class="block w-full mb-2 text-sm font-medium text-gray-700">Confirm
                  Password</label>
                <div class="relative w-full">
                  <input :type="showPassword ? 'text' : 'password'" v-model="Form.passwordVerify"
                    placeholder="e.g.: Password01^"
                    class="shadow-sm text-gray-500 bg-gray-50 border p-2.5 w-full rounded-lg" @input="validatePassword"
                    required />
                    <svg v-if="!showPassword" @click="toggleShowPassword"
                    class="absolute right-2 top-1/2 text-gray-500 transform -translate-y-1/2 h-5 w-5 cursor-pointer"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                    viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-width="2"
                      d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                    <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    :class="showPassword ? 'text-gray-700' : 'text-gray-400'"
                  </svg>
                  <svg v-else @click="toggleShowPassword"  class="absolute text-gray-500 right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 cursor-pointer"
                  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                </svg>
                 
                </div>
                <div v-if="!isPasswordMatch && Form.passwordVerify !== ''" class="text-red-500 text-sm mt-2">
                    Password and confirmation do not match.
                  </div>
              </div>

              <ul class="text-sm mt-4">
                <li class="flex mb-1 items-stretch align-top justify-start"
                  :class="{
                    'text-green-500': passwordCriteria.uppercase && isSaveClicked,
                    'text-red-500': !passwordCriteria.uppercase && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }">
                  <i
                   class="fa-solid fa-check mt-1 mr-2"
                   :class="{
                    'text-green-500': passwordCriteria.uppercase && isSaveClicked,
                    'text-red-500': !passwordCriteria.uppercase && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }"
                   >
                  
                  </i>
                  Include uppercase letter (A-Z)
                </li>
                <li class="flex mb-1 items-stretch align-top justify-start"
                  :class="{
                    'text-green-500': passwordCriteria.lowercase && isSaveClicked,
                    'text-red-500': !passwordCriteria.lowercase && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }">
                     <i
                   class="fa-solid fa-check mt-1 mr-2"
                   :class="{
                    'text-green-500': passwordCriteria.lowercase && isSaveClicked,
                    'text-red-500': !passwordCriteria.lowercase && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }"
                   >
                  
                  </i>
                  Include lowercase letter (a-z)
                </li>
                <li class="flex mb-1 items-stretch align-top justify-start"
                  :class="{
                    'text-green-500': passwordCriteria.minLength && isSaveClicked,
                    'text-red-500': !passwordCriteria.minLength && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }">
                     <i
                   class="fa-solid fa-check mt-1 mr-2"
                   :class="{
                    'text-green-500': passwordCriteria.minLength && isSaveClicked,
                    'text-red-500': !passwordCriteria.minLength && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }"
                   >
                  
                  </i>
                  Minimum 8 characters
                </li>
                <li class="flex items-stretch align-top justify-start"
                  :class="{
                    'text-green-500': passwordCriteria.numberSymbol && isSaveClicked,
                    'text-red-500': !passwordCriteria.numberSymbol && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }">
                     <i
                   class="fa-solid fa-check mt-1 mr-2"
                   :class="{
                    'text-green-500': passwordCriteria.numberSymbol && isSaveClicked,
                    'text-red-500': !passwordCriteria.numberSymbol && isSaveClicked,
                    'text-gray-500': !isSaveClicked
                  }"
                   >
                  
                  </i>
                  Include number & symbol
                </li>
              </ul>

              <button :class="{'opacity-50 bg-gray-300 pointer-events-none text-gray-700': isButtonDisabled, 'text-white bg-blue-500': !isButtonDisabled }" class="w-full p-2.5 mt-2 mb-2 rounded-md" @click="actionReset">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from '../../components/form/Toast.vue'
import { EventBus } from '../../../src/event-bus';  
import { mapActions, mapState } from "vuex";

export default {
  name: "resetPassword",
  components: {
    Toast
  },
  data() {
    return {
      Form: {
        password: '',
        passwordVerify: '',
      },
      passwordCriteria: {
        uppercase: false,
        lowercase: false,
        minLength: false,
        numberSymbol: false,
      },
      showPassword: false,
      isSaveClicked: false,
    };
  },
  computed: {
    ...mapState({
      success: (state) => state.forgetPassword.isSuccess,
      message: (state) => state.forgetPassword.successMessage,
      isLoading: (state) => state.forgetPassword.isLoading,
      statusCode: (state) => state.forgetPassword.statusCode,
    //  token: (state) => state.forgetPassword.token,
      isError: (state) => state.forgetPassword.isError,
      isErrorPage: (state) => state.forgetPassword.isErrorPage,
      isPage: (state) => state.forgetPassword.isPage,
    }),
    isDisable() {
      return !(this.Form.password && this.Form.password.length > 4 && this.Form.passwordVerify && this.Form.passwordVerify.length > 4);
    },
    isPasswordMatch() {
      return this.Form.password === this.Form.passwordVerify;
    },
    isButtonDisabled() {
      return this.Form.passwordVerify === '' || !this.isPasswordMatch;
    }
  },
  created() {
   let data = this.$route.params.data;
   this.data = data;
   this.checkToken({ data });
  }, mounted() {
    this.actionCheck();
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Forgot Password | Content Management System Ebooks Gramedia.com';
        }
    },
    success: function () {
      if(this.success){
        EventBus.$emit('show-toast', {
          message:this.message,
          type: 'success',
          });
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 1500);
      }
    },
    isError : function (){
      if(this.isError){
        EventBus.$emit('show-toast', {
          message: this.message,
          type: 'success',
          });
      }
    }
  },
  methods: {
    ...mapActions("forgetPassword", ["checkToken", "resetPassword"]),
    actionCheck() {
      let data = this.$route.params.data;
      this.data = data;
      this.checkToken({ data });
    },
    validatePassword() {
      const { password } = this.Form;
      this.passwordCriteria.uppercase = /[A-Z]/.test(password);
      this.passwordCriteria.lowercase = /[a-z]/.test(password);
      this.passwordCriteria.minLength = password.length >= 8;
      this.passwordCriteria.numberSymbol = /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);
 
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    actionReset() {
      this.isSaveClicked = true;
      this.validatePassword();

      if (!this.isValid()  ) {
           EventBus.$emit('show-toast', { type: 'error', message: 'Invalid format. Please enter the required character.' });
      } else {
        let payload = {
          newPassword: this.Form.password,
          data: this.$route.params.data,
        };
        this.resetPassword(payload)
      }
    },
    isValid() {
      return (
        this.passwordCriteria.uppercase &&
        this.passwordCriteria.lowercase &&
        this.passwordCriteria.minLength &&
        this.passwordCriteria.numberSymbol 
      );
    },
  },
};
</script>

<style scoped>
@import url("../../assets/fontawesome-6/css/all.css");
.text-red-500 {
  color: red;
}
.text-gray-500 {
  color: gray;
}
</style>
